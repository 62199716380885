import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Reducer } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { TAppActions } from '../rootDuck';
import { call, put, takeLatest } from 'redux-saga/effects';

import { ActionsUnion, createAction } from '../../utils/action-helper';
import { IServerResponse } from '../../interfaces/server';
import { IBill } from '../../interfaces/bill';
import { getBillById, getBills } from '../../crud/bill.crud';
import { IStore } from '../../interfaces/store';
import { getStoreById, getStores } from '../../crud/store.crud';

const CLEAR_FETCH = 'bills/CLEAR_FETCH';
const FETCH_REQUEST = 'bills/FETCH_REQUEST';
const FETCH_SUCCESS = 'bills/FETCH_SUCCESS';
const FETCH_FAIL = 'bills/FETCH_FAIL';

const CLEAR_FETCH_BY_ID = 'bills/CLEAR_FETCH_BY_ID';
const FETCH_BY_ID_REQUEST = 'bills/FETCH_BY_ID_REQUEST';
const FETCH_BY_ID_SUCCESS = 'bills/FETCH_BY_ID_SUCCESS';
const FETCH_BY_ID_FAIL = 'bills/FETCH_BY_ID_FAIL';

const SET_PAID = 'bills/SET_PAID';

export interface IInitialState {
  bills: IBill[];
  page: number;
  per_page: number;
  total: number;
  loading: boolean;
  success: boolean;
  error: string | null;

  bill: IBill | null;
  byIdLoading: boolean;
  byIdSuccess: boolean;
  byIdError: string | null;
}

const initialState: IInitialState = {
  bills: [],
  page: 1,
  per_page: 20,
  total: 0,
  loading: false,
  success: false,
  error: null,

  bill: null,
  byIdLoading: false,
  byIdSuccess: false,
  byIdError: null,
};

export const reducer: Reducer<IInitialState & PersistPartial, TAppActions> = persistReducer(
  { storage, key: 'bills', whitelist: ['user', 'authToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      case CLEAR_FETCH: {
        return { ...state, loading: false, error: null, success: false };
      }

      case FETCH_REQUEST: {
        return {
          ...state,
          bills: [],
          loading: true,
          error: null,
          success: false,
        };
      }

      case FETCH_SUCCESS: {
        return {
          ...state,
          page: action.payload.page,
          per_page: action.payload.per_page,
          total: action.payload.total,
          bills: action.payload.data,
          loading: false,
          success: true,
        };
      }

      case FETCH_FAIL: {
        return { ...state, loading: false, error: action.payload };
      }

      case CLEAR_FETCH_BY_ID: {
        return { ...state, bill: null, byIdLoading: false, byIdError: null, byIdSuccess: false };
      }

      case FETCH_BY_ID_REQUEST: {
        return {
          ...state,
          bill: null,
          byIdLoading: true,
          byIdError: null,
          byIdSuccess: false,
        };
      }

      case FETCH_BY_ID_SUCCESS: {
        return { ...state, bill: action.payload.data, byIdLoading: false, byIdSuccess: true };
      }

      case FETCH_BY_ID_FAIL: {
        return { ...state, byIdLoading: false, byIdError: action.payload };
      }

      case SET_PAID: {
        const bill = state.bill ? { ...state.bill, paid: true } : null;

        return {
          ...state,
          bill,
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  clearFetch: () => createAction(CLEAR_FETCH),
  fetchRequest: (payload: { page: number; perPage: number }) =>
    createAction(FETCH_REQUEST, payload),
  fetchSuccess: (payload: IServerResponse<IBill[]>) => createAction(FETCH_SUCCESS, payload),
  fetchFail: (payload: string) => createAction(FETCH_FAIL, payload),

  clearFetchById: () => createAction(CLEAR_FETCH_BY_ID),
  fetchByIdRequest: (payload: number) => createAction(FETCH_BY_ID_REQUEST, payload),
  fetchByIdSuccess: (payload: IServerResponse<IBill>) => createAction(FETCH_BY_ID_SUCCESS, payload),
  fetchByIdFail: (payload: string) => createAction(FETCH_BY_ID_FAIL, payload),

  setPaid: () => createAction(SET_PAID),
};

export type TActions = ActionsUnion<typeof actions>;

function* fetchSaga({ payload }: { payload: { page: number; perPage: number } }) {
  try {
    const { data }: { data: IServerResponse<IBill[]> } = yield call(() => getBills(payload));
    yield put(actions.fetchSuccess(data));
  } catch (e) {
    yield put(actions.fetchFail(e.response.data.message));
  }
}

function* fetchByIdSaga({ payload }: { payload: number }) {
  try {
    const { data }: { data: IServerResponse<IBill> } = yield call(() => getBillById(payload));
    yield put(actions.fetchByIdSuccess(data));
  } catch (e) {
    yield put(actions.fetchByIdFail(e.response.data.message));
  }
}

export function* saga() {
  yield takeLatest<ReturnType<typeof actions.fetchRequest>>(FETCH_REQUEST, fetchSaga);
  yield takeLatest<ReturnType<typeof actions.fetchByIdRequest>>(FETCH_BY_ID_REQUEST, fetchByIdSaga);
}
