import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { connect, ConnectedProps } from 'react-redux';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { makeStyles, TextField } from '@material-ui/core';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import ButtonWithLoader from '../../components/ui/Buttons/ButtonWithLoader';
import { Link, RouteComponentProps, useLocation } from 'react-router-dom';

import { IAppState } from '../../store/rootDuck';
import { actions as authActions } from '../../store/ducks/auth.duck';
import Preloader from '../../components/ui/Preloader/Preloader';

const useStyles = makeStyles((theme) => ({
  languageBlock: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Login: React.FC<WrappedComponentProps & TPropsFromRedux> = ({
  intl,
  user,
  authToken,
  clearLogin,
  login,
  loginLoading,
  loginSuccess,
  loginErrors,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const query = useQuery();

  const loginQuery = query.get('login');
  const emailQuery = query.get('email');
  const companyQuery = query.get('company');
  const md5Query = query.get('md5');

  useEffect(() => {
    if (loginSuccess || loginErrors) {
      enqueueSnackbar(loginSuccess ? '' : `${intl.formatMessage({ id: 'ERROR' })} ${loginErrors}`, {
        variant: loginSuccess ? 'success' : 'error',
      });
    }
    return () => {
      clearLogin();
    };
  }, [loginSuccess, loginErrors, enqueueSnackbar, clearLogin, intl]);

  useEffect(() => {
    if (md5Query && emailQuery) {
      login({
        login: '',
        password: '',
        query: { email: emailQuery, md5: md5Query, company: companyQuery },
      });
    }
  }, [md5Query, emailQuery]);

  if (loginLoading && emailQuery && md5Query) {
    return <Preloader />;
  }

  return (
    <>
      <div className="kt-login__body">
        <div className="kt-login__form">
          <div className="kt-login__title">
            <h3>
              <FormattedMessage id="AUTH.LOGIN.TITLE" />
            </h3>
          </div>

          <Formik
            initialValues={{
              login: !!loginQuery ? loginQuery.toString() : '',
              password: !!loginQuery ? '' : '',
            }}
            validationSchema={Yup.object().shape({
              login: Yup.string().required(intl.formatMessage({ id: 'AUTH.INPUT.LOGIN_REQUIRED' })),
              password: Yup.string().required(
                intl.formatMessage({ id: 'AUTH.INPUT.PASSWORD_REQUIRED' })
              ),
            })}
            onSubmit={(values) => {
              login({
                login: values.login,
                password: values.password,
                query: { email: emailQuery, md5: md5Query },
              });
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
              <form
                noValidate={true}
                autoComplete="off"
                className="kt-form"
                onSubmit={handleSubmit}
              >
                <div className="form-group">
                  <TextField
                    type="email"
                    label={intl.formatMessage({
                      id: 'AUTH.INPUT.LOGIN',
                    })}
                    margin="normal"
                    className="kt-width-full"
                    name="login"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.login}
                    helperText={touched.login && errors.login}
                    error={Boolean(touched.login && errors.login)}
                  />
                </div>

                <div className="form-group">
                  <TextField
                    autoComplete="off"
                    type="password"
                    margin="normal"
                    label={intl.formatMessage({
                      id: 'AUTH.INPUT.PASSWORD',
                    })}
                    className="kt-width-full"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    helperText={touched.password && errors.password}
                    error={Boolean(touched.password && errors.password)}
                  />
                </div>

                <div className="kt-login__actions">
                  <ButtonWithLoader disabled={loginLoading} loading={loginLoading}>
                    <FormattedMessage id="AUTH.LOGIN.BUTTON" />
                  </ButtonWithLoader>

                  <Link to="/auth/forgot-password" className="kt-link kt-login__link-forgot">
                    <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
                  </Link>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    user: state.auth.user,
    authToken: state.auth.authToken,
    loginLoading: state.auth.loginLoading,
    loginSuccess: state.auth.loginSuccess,
    loginErrors: state.auth.loginErrors,
  }),
  {
    clearLogin: authActions.clearLogin,
    login: authActions.loginRequest,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default injectIntl(connector(Login));
