import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Reducer } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { TAppActions } from '../rootDuck';
import { put, takeLatest, call } from 'redux-saga/effects';

import { IOrderForEdit, IOrder } from '../../interfaces/order';
import { ActionsUnion, createAction } from '../../utils/action-helper';
import {
  getOrders,
  deleteOrder,
  editOrder,
  getOrderById,
  importOrders,
  checkOrdersInit,
  checkOrdersSend,
  setMainOrder,
} from '../../crud/orders.crud';
import { IServerResponse } from '../../interfaces/server';
import { getResponseMessage } from '../../utils/utils';

const CLEAR_IMPORT = 'companies/CLEAR_IMPORT_REQUEST';
const FETCH_IMPORT_REQUEST = 'companies/FETCH_IMPORT_REQUEST';
const FETCH_IMPORT_SUCCESS = 'companies/FETCH_IMPORT_SUCCESS';
const FETCH_IMPORT_FAIL = 'companies/FETCH_IMPORT_FAIL';

const FETCH_REQUEST = 'orders/FETCH_REQUEST';
const FETCH_SUCCESS = 'orders/FETCH_SUCCESS';
const FETCH_FAIL = 'orders/FETCH_FAIL';

const FETCH_BY_ID_REQUEST = 'orders/FETCH_BY_ID_REQUEST';
const FETCH_BY_ID_SUCCESS = 'orders/FETCH_BY_ID_SUCCESS';
const FETCH_BY_ID_FAIL = 'orders/FETCH_BY_ID_FAIL';

const CLEAR_EDIT = 'orders/CLEAR_EDIT';
const EDIT_REQUEST = 'orders/EDIT_REQUEST';
const EDIT_SUCCESS = 'orders/EDIT_SUCCESS';
const EDIT_FAIL = 'orders/EDIT_FAIL';

const EDIT_STATUS_REQUEST = 'orders/EDIT_STATUS_REQUEST';
const EDIT_STATUS_SUCCESS = 'orders/EDIT_STATUS_SUCCESS';
const EDIT_STATUS_FAIL = 'orders/EDIT_STATUS_FAIL';

const DELETE_REQUEST = 'orders/DELETE_REQUEST';
const DELETE_SUCCESS = 'orders/DELETE_SUCCESS';
const DELETE_FAIL = 'orders/DELETE_FAIL';

const CLEAR_FILTER = 'orders/CLEAR_FILTER';
const SET_FILTER = 'orders/SET_FILTER';

const CHECK_ORDER_INIT_CLEAR = 'orders/CHECK_ORDER_INIT_CLEAR';
const CHECK_ORDER_INIT_REQUEST = 'orders/CHECK_ORDER_INIT_REQUEST';
const CHECK_ORDER_INIT_SUCCESS = 'orders/CHECK_ORDER_INIT_SUCCESS';
const CHECK_ORDER_INIT_FAIL = 'orders/CHECK_ORDER_INIT_FAIL';

const CHECK_ORDER_POST_CLEAR = 'orders/CHECK_ORDER_POST_CLEAR';
const CHECK_ORDER_POST_REQUEST = 'orders/CHECK_ORDER_POST_REQUEST';
const CHECK_ORDER_POST_SUCCESS = 'orders/CHECK_ORDER_POST_SUCCESS';
const CHECK_ORDER_POST_FAIL = 'orders/CHECK_ORDER_POST_FAIL';

const SET_MAIN_CLEAR = 'orders/SET_MAIN_CLEAR';
const SET_MAIN_REQUEST = 'orders/SET_MAIN_REQUEST';
const SET_MAIN_SUCCESS = 'orders/SET_MAIN_SUCCESS';
const SET_MAIN_FAIL = 'orders/SET_MAIN_FAIL';

export interface IInitialState {
  page: number;
  per_page: number;
  total: number;
  orders: IOrder[] | undefined;
  loading: boolean;
  success: boolean;
  error: string | null;

  order: IOrder | undefined;
  byIdLoading: boolean;
  byIdSuccess: boolean;
  byIdError: string | null;

  editLoading: boolean;
  editSuccess: boolean;
  editError: string | null;

  delError: string | null;

  filter: {
    address_id: string;
  };

  importLoading: boolean;
  importError: string | null;
  importSuccess: boolean;

  checkOrderInitLoading: boolean;
  checkOrderInitSuccess: boolean;
  checkOrderInitError: string | null;

  checkOrderSendLoading: boolean;
  checkOrderSendSuccess: boolean;
  checkOrderSendError: string | null;

  setMainLoading: boolean;
  setMainSuccess: boolean;
  setMainError: string | null;
}

const initialState: IInitialState = {
  page: 1,
  per_page: 20,
  total: 0,
  orders: undefined,
  loading: false,
  success: false,
  error: null,

  order: undefined,
  byIdLoading: false,
  byIdSuccess: false,
  byIdError: null,

  editLoading: false,
  editSuccess: false,
  editError: null,

  delError: null,

  filter: {
    address_id: '',
  },

  importLoading: false,
  importError: null,
  importSuccess: false,

  checkOrderInitLoading: false,
  checkOrderInitSuccess: false,
  checkOrderInitError: null,

  checkOrderSendLoading: false,
  checkOrderSendSuccess: false,
  checkOrderSendError: null,

  setMainLoading: false,
  setMainSuccess: false,
  setMainError: null,
};

export const reducer: Reducer<IInitialState & PersistPartial, TAppActions> = persistReducer(
  { storage, key: 'models', whitelist: ['user', 'authToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      case CHECK_ORDER_INIT_CLEAR: {
        return {
          ...state,
          checkOrderInitLoading: false,
          checkOrderInitError: null,
          checkOrderInitSuccess: false,
        };
      }

      case CHECK_ORDER_INIT_REQUEST: {
        return {
          ...state,
          checkOrderInitLoading: true,
          checkOrderInitError: null,
          checkOrderInitSuccess: false,
        };
      }

      case CHECK_ORDER_INIT_SUCCESS: {
        return {
          ...state,
          checkOrderInitLoading: false,
          checkOrderInitError: null,
          checkOrderInitSuccess: true,
        };
      }

      case CHECK_ORDER_INIT_FAIL: {
        return { ...state, checkOrderInitLoading: false, checkOrderInitError: action.payload };
      }

      case CHECK_ORDER_POST_CLEAR: {
        return {
          ...state,
          checkOrderSendLoading: false,
          checkOrderSendError: null,
          checkOrderSendSuccess: false,
        };
      }

      case CHECK_ORDER_POST_REQUEST: {
        return {
          ...state,
          checkOrderSendLoading: true,
          checkOrderSendError: null,
          checkOrderSendSuccess: false,
        };
      }

      case CHECK_ORDER_POST_SUCCESS: {
        return {
          ...state,
          checkOrderSendLoading: false,
          checkOrderSendError: null,
          checkOrderSendSuccess: true,
        };
      }

      case CHECK_ORDER_POST_FAIL: {
        return { ...state, checkOrderSendLoading: false, checkOrderSendError: action.payload };
      }

      case SET_MAIN_CLEAR: {
        return {
          ...state,
          setMainLoading: false,
          setMainSuccess: false,
          setMainError: null,
        };
      }

      case SET_MAIN_REQUEST: {
        return {
          ...state,
          setMainLoading: true,
          setMainError: null,
          setMainSuccess: false,
        };
      }

      case SET_MAIN_SUCCESS: {
        if (state.order) {
          const oldMain = state.order.shopify_orders.find((order) => order.main === true);
          const newMain = state.order.shopify_orders.find((order) => order.id === action.payload);

          if (oldMain) {
            oldMain.main = false;
          }

          if (newMain) {
            newMain.main = true;
          }
        }

        return {
          ...state,
          setMainLoading: false,
          setMainError: null,
          setMainSuccess: true,
        };
      }

      case SET_MAIN_FAIL: {
        return { ...state, setMainLoading: false, setMainError: action.payload };
      }

      case CLEAR_IMPORT: {
        return {
          ...state,
          importLoading: false,
          importError: null,
          importSuccess: false,
        };
      }

      case FETCH_IMPORT_REQUEST: {
        return {
          ...state,
          importLoading: true,
          importError: null,
          importSuccess: false,
        };
      }

      case FETCH_IMPORT_SUCCESS: {
        return {
          ...state,
          importLoading: false,
          importError: null,
          importSuccess: true,
        };
      }

      case FETCH_IMPORT_FAIL: {
        return { ...state, importLoading: false, importError: action.payload };
      }

      case CLEAR_FILTER: {
        return {
          ...state,
          filter: {
            address_id: '',
            company_id: 0,
          },
        };
      }

      case SET_FILTER: {
        return {
          ...state,
          filter: {
            ...state.filter,
            ...action.payload,
          },
        };
      }

      case FETCH_REQUEST: {
        return {
          ...state,
          orders: undefined,
          loading: true,
          success: false,
          error: null,
        };
      }

      case FETCH_SUCCESS: {
        return {
          ...state,
          page: action.payload.page,
          per_page: action.payload.per_page,
          total: action.payload.total,
          orders: action.payload.data,
          loading: false,
          success: true,
        };
      }

      case FETCH_FAIL: {
        return { ...state, loading: false, error: action.payload };
      }

      case FETCH_BY_ID_REQUEST: {
        return {
          ...state,
          order: undefined,
          byIdLoading: true,
          byIdSuccess: false,
          byIdError: null,
        };
      }

      case FETCH_BY_ID_SUCCESS: {
        return {
          ...state,
          order: action.payload.data,
          byIdLoading: false,
          byIdSuccess: true,
        };
      }

      case FETCH_BY_ID_FAIL: {
        return { ...state, byIdLoading: false, byIdError: action.payload };
      }

      case CLEAR_EDIT: {
        return {
          ...state,
          order: undefined,
          editLoading: false,
          editSuccess: false,
          editError: null,
          delError: null,
        };
      }

      case EDIT_REQUEST: {
        return { ...state, editLoading: true, editSuccess: false, editError: null };
      }

      case EDIT_SUCCESS: {
        return { ...state, editLoading: false, editSuccess: true };
      }

      case EDIT_FAIL: {
        return { ...state, editLoading: false, editError: action.payload };
      }

      case DELETE_FAIL: {
        return { ...state, delError: action.payload };
      }

      case EDIT_STATUS_REQUEST: {
        return { ...state, loading: true };
      }
      case EDIT_STATUS_FAIL: {
        return { ...state, loading: false, error: action.payload };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  fetchRequest: (payload: { page: number; perPage: number; address_id?: string }) =>
    createAction(FETCH_REQUEST, payload),
  fetchSuccess: (payload: IServerResponse<IOrder[]>) => createAction(FETCH_SUCCESS, payload),
  fetchFail: (payload: string) => createAction(FETCH_FAIL, payload),

  fetchByIdRequest: (payload: number) => createAction(FETCH_BY_ID_REQUEST, payload),
  fetchByIdSuccess: (payload: IServerResponse<IOrder>) =>
    createAction(FETCH_BY_ID_SUCCESS, payload),
  fetchByIdFail: (payload: string) => createAction(FETCH_BY_ID_FAIL, payload),

  clearEdit: () => createAction(CLEAR_EDIT),
  editRequest: (payload: { id: number; data: IOrderForEdit }) =>
    createAction(EDIT_REQUEST, payload),
  editSuccess: () => createAction(EDIT_SUCCESS),
  editFail: (payload: string) => createAction(EDIT_FAIL, payload),

  editStatusRequest: (payload: {
    id: number;
    data: IOrderForEdit;
    page: number;
    perPage: number;
  }) => createAction(EDIT_STATUS_REQUEST, payload),
  editStatusSuccess: () => createAction(EDIT_STATUS_SUCCESS),
  editStatuFail: (payload: string) => createAction(EDIT_STATUS_FAIL, payload),

  delRequest: (payload: { id: number; page: number; perPage: number }) =>
    createAction(DELETE_REQUEST, payload),
  delSuccess: () => createAction(DELETE_SUCCESS),
  delFail: (payload: string) => createAction(DELETE_FAIL, payload),

  clearFilter: () => createAction(CLEAR_FILTER),
  setFilter: (payload: { address_id?: string; company_id?: number }) =>
    createAction(SET_FILTER, payload),

  clearImport: () => createAction(CLEAR_IMPORT),
  importRequest: (payload: { companyId: number }) => createAction(FETCH_IMPORT_REQUEST, payload),
  importSuccess: () => createAction(FETCH_IMPORT_SUCCESS),
  importFail: (payload: string) => createAction(FETCH_IMPORT_FAIL, payload),

  checkOrderInitRequest: (payload: { order_id: number; code: string }) =>
    createAction(CHECK_ORDER_INIT_REQUEST, payload),

  checkOrderInitSuccess: () => createAction(CHECK_ORDER_INIT_SUCCESS),
  checkOrderInitFail: (payload: string) => createAction(CHECK_ORDER_INIT_FAIL, payload),
  checkOrderInitClear: () => createAction(CHECK_ORDER_INIT_CLEAR),

  checkOrderSendRequest: (payload: {
    order_id: number;
    code: string;
    name: string;
    country: string;
    address: string;
    region: string;
    postcode: string;
    phone: string;
  }) => createAction(CHECK_ORDER_POST_REQUEST, payload),
  checkOrderSendSuccess: () => createAction(CHECK_ORDER_POST_SUCCESS),
  checkOrderSendFail: (payload: string) => createAction(CHECK_ORDER_POST_FAIL, payload),
  checkOrderSendClear: () => createAction(CHECK_ORDER_POST_CLEAR),

  clearSetMain: () => createAction(SET_MAIN_CLEAR),
  setMainRequest: (payload: { id: number }) => createAction(SET_MAIN_REQUEST, payload),
  setMainSuccess: (payload: number) => createAction(SET_MAIN_SUCCESS, payload),
  setMainFail: (payload: string) => createAction(SET_MAIN_FAIL, payload),
};

export type TActions = ActionsUnion<typeof actions>;

function* fetchSaga({
  payload,
}: {
  payload: { page: number; perPage: number; address_id?: string };
}) {
  try {
    const { data }: { data: IServerResponse<IOrder[]> } = yield call(() =>
      getOrders(payload.page, payload.perPage, payload.address_id)
    );
    yield put(actions.fetchSuccess(data));
  } catch (e) {
    yield put(actions.fetchFail(getResponseMessage(e.response.data.message)));
  }
}

function* delSaga({ payload }: { payload: { id: number; page: number; perPage: number } }) {
  try {
    yield call(() => deleteOrder(payload.id));
    yield put(
      actions.fetchRequest({
        page: payload.page,
        perPage: payload.perPage,
      })
    );
  } catch (e) {
    yield put(actions.delFail(getResponseMessage(e.response.data.message)));
  }
}

function* editStatusSaga({
  payload,
}: {
  payload: { id: number; page: number; perPage: number; data: IOrderForEdit };
}) {
  try {
    yield call(() => editOrder(payload.id, payload.data));
    yield put(
      actions.fetchRequest({
        page: payload.page,
        perPage: payload.perPage,
      })
    );
  } catch (e) {
    yield put(actions.editStatuFail(getResponseMessage(e.response.data.message)));
  }
}

function* fetchByIdSaga({ payload }: { payload: number }) {
  try {
    const { data }: { data: IServerResponse<IOrder> } = yield call(() => getOrderById(payload));
    yield put(actions.fetchByIdSuccess(data));
  } catch (e) {
    yield put(actions.fetchByIdFail(getResponseMessage(e.response.data.message)));
  }
}

function* editSaga({ payload: { id, data } }: { payload: { id: number; data: IOrderForEdit } }) {
  try {
    yield call(() => editOrder(id, data));

    yield put(actions.editSuccess());
  } catch (e) {
    yield put(actions.editFail(getResponseMessage(e)));
  }
}

function* importOrdersSaga({ payload }: { payload: { companyId: number } }) {
  try {
    yield call(() => importOrders(payload.companyId));
    yield put(actions.importSuccess());
  } catch (e) {
    yield put(actions.importFail(getResponseMessage(e.response.data.message)));
  }
}

function* checkOrderInitSaga({ payload }: { payload: { order_id: number; code: string } }) {
  try {
    yield call(() => checkOrdersInit(payload));
    yield put(actions.checkOrderInitSuccess());
  } catch (e) {
    yield put(actions.checkOrderInitFail(getResponseMessage(e.response.data.message)));
  }
}

function* setMainSaga({ payload }: { payload: { id: number } }) {
  try {
    yield call(() => setMainOrder(payload.id));
    yield put(actions.setMainSuccess(payload.id));
  } catch (e) {
    yield put(actions.setMainFail(getResponseMessage(e.response.data.message)));
  }
}

function* checkOrderSendSaga({
  payload,
}: {
  payload: {
    order_id: number;
    code: string;
    name: string;
    country: string;
    address: string;
    region: string;
    postcode: string;
    phone: string;
  };
}) {
  try {
    yield call(() => checkOrdersSend(payload));
    yield put(actions.checkOrderSendSuccess());
  } catch (e) {
    yield put(actions.checkOrderSendFail(getResponseMessage(e.response.data.message)));
  }
}

export function* saga() {
  yield takeLatest<ReturnType<typeof actions.fetchRequest>>(FETCH_REQUEST, fetchSaga);
  yield takeLatest<ReturnType<typeof actions.delRequest>>(DELETE_REQUEST, delSaga);
  yield takeLatest<ReturnType<typeof actions.editStatusRequest>>(
    EDIT_STATUS_REQUEST,
    editStatusSaga
  );
  yield takeLatest<ReturnType<typeof actions.fetchByIdRequest>>(FETCH_BY_ID_REQUEST, fetchByIdSaga);
  yield takeLatest<ReturnType<typeof actions.editRequest>>(EDIT_REQUEST, editSaga);
  yield takeLatest<ReturnType<typeof actions.importRequest>>(
    FETCH_IMPORT_REQUEST,
    importOrdersSaga
  );
  yield takeLatest<ReturnType<typeof actions.checkOrderInitRequest>>(
    CHECK_ORDER_INIT_REQUEST,
    checkOrderInitSaga
  );
  yield takeLatest<ReturnType<typeof actions.checkOrderSendRequest>>(
    CHECK_ORDER_POST_REQUEST,
    checkOrderSendSaga
  );
  yield takeLatest<ReturnType<typeof actions.setMainRequest>>(SET_MAIN_REQUEST, setMainSaga);
}
