import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Reducer } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';

import * as routerHelpers from '../../router/RouterHelpers';
import { IUser, IUserForRegister, IRegSuccessData } from '../../pages/auth/interfaces';
import { TAppActions } from '../rootDuck';
import { ActionsUnion, createAction } from '../../utils/action-helper';
import { IServerResponse } from '../../interfaces/server';
import { put, call, takeLatest } from 'redux-saga/effects';
import { login, recoveryPassword, newPassword, register } from '../../crud/auth.crud';

const CLEAR_REG = 'auth/CLEAR_REG';
const REG_REQUEST = 'auth/REG_REQUEST';
const REG_SUCCESS = 'auth/REG_SUCCESS';
const REG_FAIL = 'auth/REG_FAIL';

const CLEAR_LOGIN = 'auth/CLEAR_LOGIN';
const LOGIN_REQUEST = 'auth/LOGIN_REQUEST';
const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS';
const LOGIN_FAIL = 'auth/LOGIN_FAIL';

const LOGOUT = 'auth/LOGOUT';

const CLEAR_RECOVERY_PASSWORD = 'auth/CLEAR_RECOVERY_PASSWORD';
const RECOVERY_PASSWORD_REQUEST = 'auth/RECOVERY_PASSWORD_REQUEST';
const RECOVERY_PASSWORD_SUCCESS = 'auth/RECOVERY_PASSWORD_SUCCESS';
const RECOVERY_PASSWORD_FAIL = 'auth/RECOVERY_PASSWORD_FAIL';

const CLEAR_NEW_PASSWORD = 'auth/CLEAR_NEW_PASSWORD';
const NEW_PASSWORD_REQUEST = 'auth/NEW_PASSWORD_REQUEST';
const NEW_PASSWORD_SUCCESS = 'auth/NEW_PASSWORD_SUCCESS';
const NEW_PASSWORD_FAIL = 'auth/NEW_PASSWORD_FAIL';

export interface IInitialState {
  email: string | undefined;
  user: IUser | undefined;
  authToken: string | undefined;

  regLoading: boolean;
  regSuccess: boolean;
  regError: string | null;

  loginLoading: boolean;
  loginSuccess: boolean;
  loginErrors: string | null;

  recoveryPasswordLoading: boolean;
  recoveryPasswordSuccess: boolean;
  recoveryPasswordErrors: string | null;

  newPasswordLoading: boolean;
  newPasswordSuccess: boolean;
  newPasswordErrors: string | null;
}

const initialState: IInitialState = {
  email: undefined,
  user: undefined,
  authToken: undefined,

  regLoading: false,
  regSuccess: false,
  regError: null,

  loginLoading: false,
  loginSuccess: false,
  loginErrors: null,

  recoveryPasswordLoading: false,
  recoveryPasswordSuccess: false,
  recoveryPasswordErrors: null,

  newPasswordLoading: false,
  newPasswordSuccess: false,
  newPasswordErrors: null,
};

export const reducer: Reducer<IInitialState & PersistPartial, TAppActions> = persistReducer(
  { storage, key: 'auth', whitelist: ['user', 'authToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      case CLEAR_LOGIN: {
        return { ...state, loginLoading: false, loginErrors: null, loginSuccess: false };
      }

      case CLEAR_REG: {
        return { ...state, regLoading: false, regSuccess: false, regError: null };
      }

      case REG_REQUEST: {
        return {
          ...state,
          regLoading: true,
          regSuccess: false,
          regError: null,
        };
      }

      case REG_SUCCESS: {
        return {
          ...state,
          emailRequested: action.payload.data.email,
          regLoading: false,
          regSuccess: true,
        };
      }

      case LOGIN_REQUEST: {
        return {
          ...state,
          loginLoading: true,
          user: undefined,
          authToken: undefined,
          loginErrors: null,
        };
      }

      case LOGIN_SUCCESS: {
        return {
          ...state,
          loginLoading: false,
          loginSuccess: true,
          user: action.payload.data,
          authToken: action.payload.data.api_token,
          loginErrors: null,
        };
      }

      case REG_FAIL: {
        return {
          ...state,
          regLoading: false,
          regError: action.payload,
        };
      }

      case LOGIN_FAIL: {
        return {
          ...state,
          loginLoading: false,
          loginSuccess: false,
          user: undefined,
          authToken: undefined,
          loginErrors: action.payload,
        };
      }

      case LOGOUT: {
        routerHelpers.forgotLastLocation();
        return initialState;
      }

      case CLEAR_RECOVERY_PASSWORD: {
        return {
          ...state,
          recoveryPasswordLoading: false,
          recoveryPasswordErrors: null,
          recoveryPasswordSuccess: false,
        };
      }

      case RECOVERY_PASSWORD_REQUEST: {
        return {
          ...state,
          recoveryPasswordLoading: true,
          recoveryPasswordErrors: null,
        };
      }

      case RECOVERY_PASSWORD_SUCCESS: {
        return {
          ...state,
          recoveryPasswordLoading: false,
          recoveryPasswordSuccess: true,
          recoveryPasswordErrors: null,
          email: action.payload.data.email,
        };
      }

      case RECOVERY_PASSWORD_FAIL: {
        return {
          ...state,
          recoveryPasswordLoading: false,
          recoveryPasswordSuccess: false,
          recoveryPasswordErrors: action.payload,
        };
      }

      case CLEAR_NEW_PASSWORD: {
        return {
          ...state,
          newPasswordLoading: false,
          newPasswordErrors: null,
          newPasswordSuccess: false,
        };
      }

      case NEW_PASSWORD_REQUEST: {
        return {
          ...state,
          newPasswordLoading: true,
          newPasswordErrors: null,
        };
      }

      case NEW_PASSWORD_SUCCESS: {
        return {
          ...state,
          newPasswordLoading: false,
          newPasswordSuccess: true,
          newPasswordErrors: null,
        };
      }

      case NEW_PASSWORD_FAIL: {
        return {
          ...state,
          newPasswordLoading: false,
          newPasswordSuccess: false,
          newPasswordErrors: action.payload,
        };
      }

      /*     case actionTypes.Register: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;

        return { ...state, user };
      }
*/
      default:
        return state;
    }
  }
);

export const actions = {
  clearReg: () => createAction(CLEAR_REG),
  regRequest: (payload: IUserForRegister) => createAction(REG_REQUEST, payload),
  regSuccess: (payload: IServerResponse<IRegSuccessData>) => createAction(REG_SUCCESS, payload),
  regFail: (payload: string) => createAction(REG_FAIL, payload),

  clearLogin: () => createAction(CLEAR_LOGIN),
  loginRequest: (payload: { login: string; password: string; query?: { [key: string]: any } }) =>
    createAction(LOGIN_REQUEST, payload),
  loginSuccess: (payload: IServerResponse<IUser>) => createAction(LOGIN_SUCCESS, payload),
  loginFail: (payload: string) => createAction(LOGIN_FAIL, payload),

  logout: () => createAction(LOGOUT),

  clearRecoveryPassword: () => createAction(CLEAR_RECOVERY_PASSWORD),
  recoveryPasswordRequest: (payload: { email: string }) =>
    createAction(RECOVERY_PASSWORD_REQUEST, payload),
  recoveryPasswordSuccess: (payload: IServerResponse<IUser>) =>
    createAction(RECOVERY_PASSWORD_SUCCESS, payload),
  recoveryPasswordFail: (payload: string) => createAction(RECOVERY_PASSWORD_FAIL, payload),

  clearNewPassword: () => createAction(CLEAR_NEW_PASSWORD),
  newPasswordRequest: (payload: { password: string; password2: string; code: string }) =>
    createAction(NEW_PASSWORD_REQUEST, payload),
  newPasswordSuccess: (payload: IServerResponse<IUser>) =>
    createAction(NEW_PASSWORD_SUCCESS, payload),
  newPasswordFail: (payload: string) => createAction(NEW_PASSWORD_FAIL, payload),

  /* register: authToken => ({
    type: actionTypes.Register,
    payload: { authToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: user => ({ type: actionTypes.UserRequested, payload: { user } }),
  fulfillUser: user => ({ type: actionTypes.UserLoaded, payload: { user } }),
  */
};

export type TActions = ActionsUnion<typeof actions>;

function* regSaga({ payload }: { payload: IUserForRegister }) {
  try {
    const { data }: { data: IServerResponse<IRegSuccessData> } = yield call(() =>
      register(payload)
    );
    yield put(actions.regSuccess(data));
  } catch (e) {
    yield put(actions.regFail(e?.response?.data?.message || 'Connection error.'));
  }
}

function* loginSaga({
  payload,
}: {
  payload: { login: string; password: string; query?: { [key: string]: any } };
}) {
  try {
    const { data }: { data: IServerResponse<IUser> } = yield call(() =>
      login(payload.login, payload.password, payload.query)
    );
    yield put(actions.loginSuccess(data));
  } catch (e) {
    yield put(actions.loginFail(e.response.data.message));
  }
}

function* recoveryPasswordSaga({ payload }: { payload: { email: string } }) {
  try {
    const { data }: { data: IServerResponse<IUser> } = yield call(() =>
      recoveryPassword(payload.email)
    );
    yield put(actions.recoveryPasswordSuccess(data));
  } catch (e) {
    yield put(actions.recoveryPasswordFail(e.response.data.message));
  }
}

function* newPasswordSaga({
  payload,
}: {
  payload: { password: string; password2: string; code: string };
}) {
  try {
    const { data }: { data: IServerResponse<IUser> } = yield call(() => newPassword(payload));
    yield put(actions.newPasswordSuccess(data));
  } catch (e) {
    yield put(actions.newPasswordFail(e.response.data.message));
  }
}

export function* saga() {
  yield takeLatest<ReturnType<typeof actions.regRequest>>(REG_REQUEST, regSaga);
  yield takeLatest<ReturnType<typeof actions.loginRequest>>(LOGIN_REQUEST, loginSaga);
  yield takeLatest<ReturnType<typeof actions.recoveryPasswordRequest>>(
    RECOVERY_PASSWORD_REQUEST,
    recoveryPasswordSaga
  );
  yield takeLatest<ReturnType<typeof actions.newPasswordRequest>>(
    NEW_PASSWORD_REQUEST,
    newPasswordSaga
  );
}
