export const menuConfigItems = {
  profile: {
    root: true,
    title: 'SUBMENU.PROFILE',
    translate: 'SUBMENU.PROFILE',
    page: 'settings/profile',
  },

  logout: {
    root: true,
    title: 'MENU.LOGOUT',
    translate: 'MENU.LOGOUT',
    page: 'logout',
    materialIcon: 'logout',
  },

  products: {
    title: 'MENU.PRODUCTS',
    translate: 'MENU.PRODUCTS',
    bullet: 'dot',
    root: true,
    submenu: [
      {
        title: 'Список категорий',
        page: 'categories/list',
        translate: 'MENU.CATALOG.CATEGORIES.LIST',
        activePages: ['categories/edit', 'categories/new'],
      },
      {
        title: 'Дерево категорий',
        page: 'categories/tree',
        translate: 'MENU.CATALOG.CATEGORIES.TREE',
      },
      {
        title: 'Товары компании',
        page: 'products/list',
        translate: 'CATEGORIES.TABLE.TOOLTIP.PRODUCTS',
        activePages: ['products/edit', 'products/create'],
      },
    ],
  },

  orders: {
    root: true,
    title: 'Заказы',
    translate: 'MENU.ORDERS',
    bullet: 'dot',
    materialIcon: 'list_alt',
    submenu: [
      { translate: 'SUBMENU.ALLORDERS', title: 'SUBMENU.ALLORDERS', page: 'orders/list/full' },

      {
        translate: 'SUBMENU.ADDRESS',
        title: 'SUBMENU.ADDRESS',
        page: 'orders/address/list/full',
      },
    ],
  },

  companies: {
    root: true,
    title: 'MENU.COMPANIES',
    translate: 'MENU.COMPANIES',
    bullet: 'dot',
    page: 'companies/list',
    activePages: ['companies/edit', 'companies/new'],
    materialIcon: 'store',
  },

  users: {
    root: true,
    title: 'MENU.USERS',
    translate: 'MENU.USERS',
    bullet: 'dot',
    materialIcon: 'people',
    submenu: [
      {
        translate: 'SUBMENU.ADMINS',
        title: 'SUBMENU.ADMINS',
        page: 'users/admins',
        activePages: ['users/new', 'users/edit'],
      },
      {
        translate: 'SUBMENU.VENDORS',
        title: 'SUBMENU.VENDORS',
        page: 'users/vendors',
        activePages: ['users/new', 'users/edit'],
      },
    ],
  },

  settings: {
    root: true,
    title: 'MENU.SETTINGS',
    bullet: 'dot',
    translate: 'MENU.SETTINGS',
    submenu: [
      {
        title: 'SUBMENU.PROFILE',
        translate: 'SUBMENU.PROFILE',
        page: 'settings/profile',
      },
      {
        title: 'MENU.PRODUCTS.TYPES',
        translate: 'MENU.PRODUCTS.TYPES',
        page: 'products/types/list',
        activePages: ['products/types/create'],
      },
    ],
  },

  legal: {
    root: true,
    title: 'SUBMENU.LEGAL',
    translate: 'SUBMENU.LEGAL',
    bullet: 'dot',
    submenu: [
      {
        title: 'SUBMENU.LEGAL_TERMS',
        translate: 'SUBMENU.LEGAL_TERMS',
        page: 'documents/legacy',
      },
      {
        title: 'SUBMENU.LEGAL_PRIVACY',
        translate: 'SUBMENU.LEGAL_PRIVACY',
        page: 'documents/privacy',
      },
      {
        title: 'SUBMENU.LEGAL_SHIPPING',
        translate: 'SUBMENU.LEGAL_SHIPPING',
        page: 'documents/shipping',
      },
      {
        title: 'SUBMENU.LEGAL_REFUND',
        translate: 'SUBMENU.LEGAL_REFUND',
        page: 'documents/refund',
      },
    ],
  },

  bills: {
    root: true,
    title: 'SUBMENU.BILLS',
    translate: 'SUBMENU.BILLS',
    page: 'invoices/list',
    materialIcon: 'request_quote',
  },
  myProfile: {
    root: true,
    title: 'SUBMENU.PROFILE',
    bullet: 'dot',
    translate: 'SUBMENU.PROFILE',
    page: 'settings/profile',
    materialIcon: 'manage_accounts',
  },
};
