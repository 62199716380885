import { menuConfigItems } from '../constants/menuConfigItems';
import { TRole } from '../pages/auth/interfaces';
import store from '../store/store';

const getMenuConfig = (userRoles: TRole[] | undefined) => {
  if (userRoles === undefined) {
    return getDefaultMenu();
  } else if (userRoles[0] === 'ROLE_ADMIN') {
    return getAdminMenu();
  } else if (userRoles[0] === 'ROLE_MANAGER') {
    return getDefaultMenu();
  } else if (userRoles[0] === 'ROLE_VENDOR') {
    return getVendorMenu();
  } else if (userRoles[0] === 'ROLE_BUYER') {
    return getDefaultMenu();
  } else if (userRoles[0] === 'ROLE_GIVER') {
    return getDefaultMenu();
  }
};

const { profile, orders, users, logout, bills, companies, myProfile } = menuConfigItems;

const getDefaultMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [profile, logout],
  },
});

const getAdminMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [companies, users, orders, store, bills, myProfile, logout],
  },
});

const getVendorMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [orders, myProfile, bills, logout],
  },
});

export default getMenuConfig;
