import React from 'react';
import { connect } from 'react-redux';
import objectPath from 'object-path';
import * as builder from '../../ducks/builder';
import { makeStyles } from '@material-ui/core';
import './style.css';

const useStyle = makeStyles(() => ({
  footer: {
    backgroundColor: '#f4f8fc',
    padding: '100px 25px 0',
  },
  footer__top: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
}));

const Footer = () => {
  const classes = useStyle();

  return (
    <div className="footer fth home">
      <div className="cover-footer home">
        <div className="ftb" style={{ padding: '0 25px' }}>
          <div className="ft-top">
            <div className="ft-brand">
              <a
                target={'_blank'}
                href="https://www.yougiver.me/"
                aria-current="page"
                className="ft-brand-link w-inline-block w--current"
              >
                <img
                  src="https://uploads-ssl.webflow.com/5f3439668cfd7dfa3ce0053e/5f3e55b8ba3b2029deec43c7_logo-icon-eng.png"
                  sizes="(max-width: 479px) 24vw, (max-width: 767px) 60px, (max-width: 991px) 40px, 60px"
                  srcSet="https://uploads-ssl.webflow.com/5f3439668cfd7dfa3ce0053e/5f3e55b8ba3b2029deec43c7_logo-icon-eng-p-500.png 500w, https://uploads-ssl.webflow.com/5f3439668cfd7dfa3ce0053e/5f3e55b8ba3b2029deec43c7_logo-icon-eng.png 559w"
                  alt=""
                  className="logo-footer"
                />
              </a>
              <a
                href="https://www.tiw.swiss/"
                target="_blank"
                className="ft-brand-link-tiw w-inline-block"
              >
                <img
                  src="https://uploads-ssl.webflow.com/5f3439668cfd7dfa3ce0053e/5f438654793e6a285ad24d7c_tiw-logo.svg"
                  height
                  alt=""
                  className="tiw-logo"
                />
              </a>
              <div className="ft-brand-second">
                <a
                  href="http://www.swissinnovationchallenge.ch/"
                  target="_blank"
                  className="ft-brand-link w-inline-block"
                >
                  <img
                    src="https://uploads-ssl.webflow.com/5f3439668cfd7dfa3ce0053e/60991ba63b3b3b63ec9dc250_Reward%20Participant%20SIC%202021.png"
                    sizes="(max-width: 479px) 29vw, (max-width: 767px) 80px, (max-width: 991px) 60px, 80px"
                    srcSet="https://uploads-ssl.webflow.com/5f3439668cfd7dfa3ce0053e/60991ba63b3b3b63ec9dc250_Reward%20Participant%20SIC%202021-p-500.png 500w, https://uploads-ssl.webflow.com/5f3439668cfd7dfa3ce0053e/60991ba63b3b3b63ec9dc250_Reward%20Participant%20SIC%202021-p-800.png 800w, https://uploads-ssl.webflow.com/5f3439668cfd7dfa3ce0053e/60991ba63b3b3b63ec9dc250_Reward%20Participant%20SIC%202021.png 1200w"
                    alt=""
                    className="swiss"
                  />
                </a>
              </div>
            </div>
            <div className="ft-top-links">
              <div className="ft-top-columns">
                <div className="ft-top-title">Contact us</div>
                <div className="ft-top-cm-links">
                  <a href="mailto:info@yougiver.me" className="ft-top-link">
                    info@yougiver.me
                  </a>
                  <div className="ft-top-text">
                    Suurstoffi 8,
                    <br />
                    6343 Rotkreuz
                    <br />
                    Switzerland
                    <br />
                    YouGiver AG
                  </div>
                </div>
              </div>
              <div className="ft-top-columns">
                <div className="ft-top-title">Join us</div>
                <div className="ft-top-cm-links">
                  <a
                    target={'_blank'}
                    href="https://www.yougiver.me/recipient"
                    className="ft-top-link"
                  >
                    Receive gifts
                  </a>
                  <a target={'_blank'} href="https://www.yougiver.me/giver" className="ft-top-link">
                    Make gifts
                  </a>
                  <a
                    target={'_blank'}
                    href="https://www.yougiver.me/how-it-works"
                    className="ft-top-link"
                  >
                    How it works?
                  </a>
                  <a
                    target={'_blank'}
                    href="https://www.yougiver.me/for-corporate-clients"
                    className="ft-top-link"
                  >
                    For corporate clients
                  </a>
                  <a
                    target={'_blank'}
                    href="https://www.yougiver.me/for-sellers"
                    className="ft-top-link"
                  >
                    For Sellers
                  </a>
                  <a
                    target={'_blank'}
                    href="https://www.yougiver.me/for-developers"
                    className="ft-top-link"
                  >
                    For Developers
                  </a>
                  <a href="https://yougiver.readthedocs.io/en/latest/" className="ft-top-link">
                    API for developers
                  </a>
                </div>
              </div>
              <div className="ft-top-columns">
                <div className="ft-top-title">Service</div>
                <div className="ft-top-cm-links">
                  <a target={'_blank'} href="https://www.yougiver.me/about" className="ft-top-link">
                    About us
                  </a>
                  <a
                    target={'_blank'}
                    href="https://www.yougiver.me/support"
                    className="ft-top-link"
                  >
                    Support
                  </a>
                  <a
                    target={'_blank'}
                    href="https://www.yougiver.me/delivery"
                    className="ft-top-link"
                  >
                    Delivery
                  </a>
                </div>
              </div>
            </div>
            <div className="ft-social">
              <div className="ft-social-icons">
                <div className="ft-social-icon icon-fb">
                  <a
                    href="https://www.facebook.com/YouGiverGiftGiving"
                    target="_blank"
                    className="link-social w-inline-block"
                  />
                </div>
                <div className="ft-social-icon icon-tw">
                  <a href="#" className="link-social w-inline-block" />
                </div>
                <div className="ft-social-icon icon-inst">
                  <a
                    href="https://www.instagram.com/yougiver_official/"
                    target="_blank"
                    className="link-social w-inline-block"
                  />
                </div>
                <div className="ft-social-icon icon-in">
                  <a
                    href="https://www.linkedin.com/company/yougiver/"
                    target="_blank"
                    className="link-social w-inline-block"
                  />
                </div>
              </div>
              <div className="ft-download">
                <a
                  target={'_blank'}
                  href="https://apps.apple.com/us/app/yougiver/id1534849162"
                  className="ft-download-item di-apple w-inline-block"
                />
                <a
                  target={'_blank'}
                  href="https://play.google.com/store/apps/details?id=com.yougiver"
                  className="ft-download-item di-android w-inline-block"
                />
              </div>
            </div>
          </div>
          <div className="ft-bottom">
            <div className="ft-bottom-links">
              <div className="ft-bot-left">
                <a
                  href="https://store.yougiver.me/page/user_agreement"
                  target="_blank"
                  className="ft-bot-link"
                >
                  User agreement
                </a>
                <a
                  href="https://store.yougiver.me/page/privacy_policy"
                  target="_blank"
                  className="ft-bot-link"
                >
                  Privacy policy
                </a>
                <a
                  href="https://store.yougiver.me/page/cookies_policy"
                  target="_blank"
                  className="ft-bot-link"
                >
                  Cookies
                </a>
              </div>
              <div className="ft-bot-right">
                <div className="ft-bot-text">All rights reserved</div>
                <div className="ft-bot-text">YouGiver AG Switzerland</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (store) => ({
  fluid: objectPath.get(store.builder.layoutConfig, 'footer.self.width') === 'fluid',
  footerClasses: builder.selectors.getClasses(store, {
    path: 'footer',
    toString: true,
  }),
  footerContainerClasses: builder.selectors.getClasses(store, {
    path: 'footer_container',
    toString: true,
  }),
});

export default connect(mapStateToProps)(Footer);
