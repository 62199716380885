import axios from 'axios';
import {
  GET_USERS_URL,
  CREATE_USER_URL,
  editUserUrl,
  getUserUrl,
  SEND_INVITE_URL,
  delUserUrl,
} from '../constants';
import { IUserAddProps, IUserEditProps } from '../interfaces/user';

export function getUsersByRole(page: number, perPage: number, roles: string) {
  return axios.get(`${GET_USERS_URL}?page=${page}&per_page=${perPage}&roles=${roles}`);
}

export function getUser(id: number) {
  return axios.get(getUserUrl(id));
}

export function createUser(data: IUserAddProps) {
  return axios.post(CREATE_USER_URL, data);
}

export function editUser(id: number, data: IUserEditProps) {
  return axios.put(editUserUrl(id), data);
}

export function sendInvite(data: string) {
  return axios.get(`${SEND_INVITE_URL}?email=${data}`);
}

export function delUser(id: number) {
  return axios.delete(delUserUrl(id));
}
