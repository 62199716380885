import axios from 'axios';
import {
    LOGIN_URL,
    REGISTER_URL,
    ME_URL,
    getRecoveryPasswordUrl,
    NEW_PASSWORD_URL, RecoveryPasswordUrl,
} from '../constants';
import { IUserForRegister } from '../pages/auth/interfaces';
import { isEmail } from '../utils/utils';

export function login(login: string, password: string, query?: { [key: string]: any }) {
  let formData = new FormData();
  const emailOrLogin = isEmail(login) ? 'email' : 'login';
  formData.append(emailOrLogin, login);
  formData.append('password', password);
  let url = LOGIN_URL;

  if (query && Object.keys(query)) {
    Object.keys(query).forEach((key) => {
      const value = query[key];
      if (value) {
        const queryUrl: string = `${key}=${value}`;
        url += url === LOGIN_URL ? `?${queryUrl}` : `&${queryUrl}`;
      }
    });
  }

  return axios.post(url, formData);
}

export const register = (data: IUserForRegister) => {
  return axios.post(REGISTER_URL, data);
};

export function recoveryPassword(email: string) {
  return axios.get(RecoveryPasswordUrl, { params: { email: email }});
}

export function newPassword(data: { password: string; password2: string; code: string }) {
  return axios.post(NEW_PASSWORD_URL, data);
}

