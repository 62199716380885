import { all, fork } from 'redux-saga/effects';
import { combineReducers } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';

import * as auth from './ducks/auth.duck';
import * as users from './ducks/users.duck';
import * as profile from './ducks/profile.duck';
import * as companies from './ducks/companies.duck';

import * as orders from './ducks/orders.duck';
import * as agreement from './ducks/agreement.duck';
import * as bills from './ducks/bills.duck';

import { metronic } from '../../_metronic';

export type TAppActions =
  | auth.TActions
  | companies.TActions
  | users.TActions
  | profile.TActions
  | orders.TActions
  | agreement.TActions
  | bills.TActions;

export interface IAppState {
  i18n: typeof metronic.i18n.reducer;
  builder: typeof metronic.builder.initialState;
  auth: auth.IInitialState & PersistPartial;
  companies: companies.IInitialState & PersistPartial;
  users: users.IInitialState & PersistPartial;
  profile: profile.IInitialState & PersistPartial;
  orders: orders.IInitialState & PersistPartial;
  agreement: agreement.IInitialState;
  bills: bills.IInitialState & PersistPartial;
}

export const rootReducer: (state: IAppState, action: TAppActions) => IAppState = combineReducers<
  IAppState,
  TAppActions
>({
  auth: auth.reducer,
  companies: companies.reducer,
  users: users.reducer,
  profile: profile.reducer,
  i18n: metronic.i18n.reducer,
  builder: metronic.builder.reducer,
  orders: orders.reducer,
  agreement: agreement.reducer,
  bills: bills.reducer,
});

export function* rootSaga() {
  yield all(
    [
      auth.saga,
      companies.saga,
      users.saga,
      profile.saga,
      orders.saga,
      agreement.saga,
      bills.saga,
    ].map((saga) => fork(saga))
  );
}
