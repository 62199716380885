import React, { useState, useCallback, useEffect } from 'react';
import { Link, Switch, Route, Redirect } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';

import { I18nProvider, toAbsoluteUrl } from '../../../_metronic';
import '../../../_metronic/_assets/sass/pages/login/login-1.scss';
import Login from './Login';
import TermDialog from '../../components/ui/Dialog/TermDialog';
// import useFetch from '../../hooks/useFetch';
import EmailSentPage from './EmailSentPage';
import CheckOrder from '../general/checkOrder';
import NewPassword from './NewPassword';
import ForgotPassword from './ForgotPassword';
import ForgotPasswordEmail from './ForgotPasswordEmail';
import Footer from '../../../_metronic/layout/footer/Footer';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('md')]: {
      minHeight: '100vh',
    },
  },
}));

const ModelsPage: React.FC = () => {
  const intl = useIntl();
  const classes = useStyles();

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContentType, setDialogContentType] = useState('');

  // const [{ response }, requestUserAgreement] = useFetch("api/_public/document/user_agreement");

  // useEffect(() => {
  // requestUserAgreement();
  // }, [requestUserAgreement]);

  const handleDialogOpen = useCallback(
    (contentType) => {
      setDialogContentType(contentType);
      setOpenDialog(true);
    },
    [setDialogContentType, setOpenDialog]
  );

  function clearCookies() {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }

  useEffect(() => {
    clearCookies();
    localStorage.clear();
  }, [])

  return (
    <>
      <TermDialog
        isOpen={openDialog}
        handleClose={() => setOpenDialog(false)}
        contentType={dialogContentType}
      />
      <div className={`kt-grid kt-grid--ver kt-grid--root ${classes.root}`}>
        <div id="kt_login" className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1">
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
            <div
              className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside"
              style={{
                backgroundImage: `url(${toAbsoluteUrl('/media/bg/bg-4.jpg')})`,
              }}
            >
              <div className="kt-grid__item">
                <Link to="/" className="kt-login__logo">
                </Link>
              </div>
              <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver">
                <div className="kt-grid__item kt-grid__item--middle">
                  <h3 className="kt-login__title">
                    {intl.formatMessage({ id: 'AUTH.WELCOME.TITLE' })}
                  </h3>
                  <h4 className="kt-login__subtitle">
                    {intl.formatMessage({ id: 'AUTH.WELCOME.SUBTITLE' })}
                  </h4>
                </div>
              </div>
            </div>
            <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
              <Switch>
                <Route path="/auth/login" component={Login} />
                <Route path="/auth/email-sent/registration" component={EmailSentPage} />
                <Route path="/auth/forgot-password" component={ForgotPassword} />
                <Route path="/auth/forgot-password-email" component={ForgotPasswordEmail} />
                <Route path="/auth/change_password/:code" component={NewPassword} />
                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ModelsPage;
