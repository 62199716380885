import React, {useEffect, useState} from 'react';
import {Formik} from 'formik';
import {connect, ConnectedProps} from 'react-redux';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import {Button, Card, CardContent, Container, makeStyles, TextField} from '@material-ui/core';
import * as Yup from 'yup';
import {IAppState} from '../../../store/rootDuck';
import {ButtonWithLoader} from '../../../components/ui/Buttons';
import clsx from 'clsx';
import {useHistory, useLocation} from 'react-router-dom';
import {actions as orderActions} from '../../../store/ducks/orders.duck';
import Preloader from '../../../components/ui/Preloader/Preloader';
import useCrudSnackbar from '../../../hooks/useCrudSnackbar';

const useStyles = makeStyles((theme: any) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  card: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
  },
  saveBtn: {
    marginRight: theme.spacing(3),
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const CheckOrder: React.FC<WrappedComponentProps & TPropsFromRedux> = ({
  intl,
  checkOrderInitLoading,
  checkOrderInitSuccess,
  checkOrderInitError,

  checkOrderSendLoading,
  checkOrderSendSuccess,
  checkOrderSendError,

  clearInit,
  clearSend,

  checkOrdersInit,
  checkOrdersSend,
}) => {
  const classes = useStyles();
  const query = useQuery();
  const history = useHistory();

  const [orderData, setOrderData] = useState({ order_id: 0, code: '' });

  useEffect(() => {
    const order = query.get('order');
    const code = query.get('code');

    if (order && code) {
      setOrderData({ order_id: +order, code });
    } else {
      // window.open(window.location.pathname, '_self')?.close();
      history.push('/');
    }
  }, []);

  useEffect(() => {
    if (orderData.order_id && orderData.code) {
      checkOrdersInit(orderData);
    }

    return () => {
      clearInit();
      clearSend();
    };
  }, [orderData]);

  useEffect(() => {
    if (checkOrderInitError) {
      history.push('/');
    }
  }, [checkOrderInitError]);

  useCrudSnackbar({
    success: checkOrderSendSuccess,
    error: checkOrderSendError,
    clear: clearSend,
    successMessage: 'Success',
    errorMessage: `${intl.formatMessage({ id: 'ERROR' })}: ${checkOrderSendError}`,
    afterSuccess: () => {
      window.close();
    },
  });

  if (checkOrderInitLoading) return <Preloader />;

  return (
    <>
      <Container maxWidth="sm" className={classes.container}>
        <Card className={classes.card}>
          <CardContent>
            <div className="kt-login__body">
              <div className="kt-login__form">
                <div className="kt-login__title">
                  <h3>
                    <FormattedMessage id="CHECK_ORDER.TITLE" />
                  </h3>
                </div>

                <Formik
                  initialValues={{
                    name: '',
                    country: '',
                    address: '',
                    region: '',
                    postcode: '',
                    phone: '',
                  }}
                  validationSchema={Yup.object().shape({
                    name: Yup.string().required(
                      intl.formatMessage({ id: 'CHECK_ORDER.INPUT.REQUIRED' })
                    ),
                    country: Yup.string().required(
                      intl.formatMessage({ id: 'CHECK_ORDER.INPUT.REQUIRED' })
                    ),
                    address: Yup.string().required(
                      intl.formatMessage({ id: 'CHECK_ORDER.INPUT.REQUIRED' })
                    ),
                    region: Yup.string().required(
                      intl.formatMessage({ id: 'CHECK_ORDER.INPUT.REQUIRED' })
                    ),
                    postcode: Yup.string().required(
                      intl.formatMessage({ id: 'CHECK_ORDER.INPUT.REQUIRED' })
                    ),
                    phone: Yup.string().required(
                      intl.formatMessage({ id: 'CHECK_ORDER.INPUT.REQUIRED' })
                    ),
                  })}
                  onSubmit={(values) => {
                    checkOrdersSend({
                      ...orderData,
                      ...values,
                    });
                  }}
                >
                  {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                    <form
                      noValidate={true}
                      autoComplete="off"
                      className="kt-form"
                      onSubmit={handleSubmit}
                    >
                      <div className="form-group">
                        <TextField
                          type="text"
                          label={intl.formatMessage({
                            id: 'CHECK_ORDER.INPUT.NAME',
                          })}
                          margin="normal"
                          className="kt-width-full"
                          name="name"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.name}
                          helperText={touched.name && errors.name}
                          error={Boolean(touched.name && errors.name)}
                        />

                        <TextField
                          type="email"
                          label={intl.formatMessage({
                            id: 'CHECK_ORDER.INPUT.COUNTRY_REGION',
                          })}
                          margin="normal"
                          className="kt-width-full"
                          name="country"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.country}
                          helperText={touched.country && errors.country}
                          error={Boolean(touched.country && errors.country)}
                        />

                        <TextField
                          type="email"
                          label={intl.formatMessage({
                            id: 'CHECK_ORDER.INPUT.ADDRESS',
                          })}
                          margin="normal"
                          className="kt-width-full"
                          name="address"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.address}
                          helperText={touched.address && errors.address}
                          error={Boolean(touched.address && errors.address)}
                        />

                        <TextField
                          type="email"
                          label={intl.formatMessage({
                            id: 'CHECK_ORDER.INPUT.REGION',
                          })}
                          margin="normal"
                          className="kt-width-full"
                          name="region"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.region}
                          helperText={touched.region && errors.region}
                          error={Boolean(touched.region && errors.region)}
                        />

                        <TextField
                          type="email"
                          label={intl.formatMessage({
                            id: 'CHECK_ORDER.INPUT.POST_INDEX',
                          })}
                          margin="normal"
                          className="kt-width-full"
                          name="postcode"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.postcode}
                          helperText={touched.postcode && errors.postcode}
                          error={Boolean(touched.postcode && errors.postcode)}
                        />

                        <TextField
                          type="email"
                          label={intl.formatMessage({
                            id: 'CHECK_ORDER.INPUT.PHONE',
                          })}
                          margin="normal"
                          className="kt-width-full"
                          name="phone"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.phone}
                          helperText={touched.phone && errors.phone}
                          error={Boolean(touched.phone && errors.phone)}
                        />
                      </div>

                      <div className={classes.actions}>
                        <div className={classes.saveBtn}>
                          <ButtonWithLoader
                            disabled={checkOrderSendLoading}
                            loading={checkOrderSendLoading}
                          >
                            <FormattedMessage id="COMMON.BUTTON.SAVE" />
                          </ButtonWithLoader>
                        </div>

                        <Button
                          type={'button'}
                          onClick={() => {
                            history.push('/');
                          }}
                          variant="outlined"
                          color="primary"
                          className={clsx({
                            'btn btn-secondary btn-elevate kt-login__btn-primary': true,
                          })}
                        >
                          {intl.formatMessage({ id: 'COMMON.BUTTON.CANCEL' })}
                        </Button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    checkOrderInitLoading: state.orders.checkOrderInitLoading,
    checkOrderInitSuccess: state.orders.checkOrderInitSuccess,
    checkOrderInitError: state.orders.checkOrderInitError,

    checkOrderSendLoading: state.orders.checkOrderSendLoading,
    checkOrderSendSuccess: state.orders.checkOrderSendSuccess,
    checkOrderSendError: state.orders.checkOrderSendError,
  }),
  {
    clearInit: orderActions.checkOrderInitClear,
    clearSend: orderActions.checkOrderInitClear,
    checkOrdersInit: orderActions.checkOrderInitRequest,
    checkOrdersSend: orderActions.checkOrderSendRequest,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default injectIntl(connector(CheckOrder));
