const {
  REACT_APP_API_DOMAIN = 'dev-ecom-api.yougiver.me',
  STRIPE_TEST = 'pk_test_51IQ5jWDrEZsQCPj5mMrzxmdoWDVUWnhtom3EMGQ11U93Uq3s4QUrJttyLz9xhebjtwuK3L1d9DUl6m3wUbS6gmnI00uGrZqCfC',
} = process.env;

const API_DOMAIN = `https://${REACT_APP_API_DOMAIN}`;

export { API_DOMAIN, STRIPE_TEST };

export const LOGIN_URL = 'api/user/login';
export const REGISTER_URL = 'api/user/register';
export const ME_URL = 'api/me';
export const RecoveryPasswordUrl = '/api/_p/reset_password/send_code';
export const getRecoveryPasswordUrl = (email: string): string => `/api/_p/reset_password/send_code?email=${email}`;
export const NEW_PASSWORD_URL = '/api/_p/change_password_from_link';

export const GET_USERS_URL = '/api/users';
export const CREATE_USER_URL = '/api/user/create';
export const getUserUrl = (id: number): string => `/api/user/${id}`;
export const editUserUrl = (id: number): string => `/api/user/${id}/edit`;
export const SEND_INVITE_URL = '/api/user_activate/send';
export const delUserUrl = (id: number): string => `/api/user/${id}`;

export const GET_COMPANIES_URL = '/api/companies';
export const CREATE_COMPANY_URL = '/api/company';
export const SEARCH_COMPANY_URL = '/api/companies/search';
export const getCompanyUrl = (id: number): string => `/api/company/${id}`;
export const editCompanyUrl = (id: number): string => `/api/company/${id}`;
export const delCompanyUrl = (id: number): string => `/api/company/${id}`;

export const getOrdersByStatus = () => `/api/shop/orders`;
export const getOrderurl = (id: number) => `/api/shop/order/${id}`;

export const STORES_URL = '/api/shop/stores';
export const CREATE_STORE_URL = '/api/shop/store';
export const getStoreByIdUrl = (id: number) => `api/shop/store/${id}`;

export const getImportUrl = (company_id: number) =>
  `/api/shopify/app/orders?company_id=${company_id}`;

export const getCheckOrderUrl = (data: { order_id: number; code: string }) =>
  `/api/shop/check_order?order_id=${data.order_id}&code=${data.code}`;

export const getSetMainUrl = (id: number) => `/api/shopify/order/set_main/${id}`;
