import axios from 'axios';
import { IOrderForEdit } from '../interfaces/order';
import {
  getCheckOrderUrl,
  getImportUrl,
  getOrdersByStatus,
  getOrderurl,
  getSetMainUrl,
} from '../constants';

export const getOrders = (page: number, per_page: number, address_id?: string) => {
  let url = `${getOrdersByStatus()}?page=${page}&per_page=${per_page}`;

  if (address_id) {
    url += `&address_id=${address_id}`;
  }

  return axios.get(url);
};

export const deleteOrder = (id: number) => {
  return axios.delete(getOrderurl(id));
};

export const editOrder = (id: number, order: IOrderForEdit) => axios.put(getOrderurl(id), order);

export const getOrderById = (id: number) => axios.get(getOrderurl(id));

export const importOrders = (company_id: number) => axios.post(getImportUrl(company_id), {});

export const checkOrdersInit = (data: { order_id: number; code: string }) =>
  axios.get(getCheckOrderUrl(data), {});

export const checkOrdersSend = (data: {
  order_id: number;
  code: string;
  name: string;
  country: string;
  address: string;
  region: string;
  postcode: string;
  phone: string;
}) => {
  let url = getCheckOrderUrl({ order_id: data.order_id, code: data.code });

  url = `${url}&name=${data.name}&country=${data.country}&address=${data.address}&region=${data.region}&postcode=${data.postcode}&phone=${data.phone}`;

  return axios.post(url, {});
};

export const setMainOrder = (id: number) => axios.post(getSetMainUrl(id), {});
