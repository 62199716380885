import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';

import { LayoutSplashScreen } from '../../../_metronic';
import getMenuConfig from '../../router/MenuConfig';
import * as builder from '../../../_metronic/ducks/builder';
import { IAppState } from '../../store/rootDuck';

const UsersPage = lazy(() => import('./users'));
const CompaniesPage = lazy(() => import('./companies'));
const SettingsPage = lazy(() => import('./settings'));
const OrdersPage = lazy(() => import('./orders'));
const BillsPage = lazy(() => import('./bills'));

const HomePage: React.FC<TPropsFromRedux & { userLastLocation: any }> = ({
  userRoles,
  setMenuConfig,
}) => {
  const [menuConfig] = useState(getMenuConfig(userRoles));

  useEffect(() => {
    setMenuConfig(menuConfig);
  }, [setMenuConfig, menuConfig]);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /catalog. */
          <Redirect exact from="/" to="/orders/list/full" />
        }

        <Route path="/settings" component={SettingsPage} />
        <Route path="/users" component={UsersPage} />
        <Route path="/companies" component={CompaniesPage} />
        <Route path="/orders" component={OrdersPage} />
        <Route path="/invoices" component={BillsPage} />
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
};

const connector = connect(
  (state: IAppState) => ({
    userRoles: state.auth.user?.roles,
  }),
  {
    setMenuConfig: builder.actions.setMenuConfig,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(HomePage);
